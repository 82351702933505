// eslint-disable
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    username
    fullName
    email
    sourceUrl
    phone
    clientCode
    conversations {
      items {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    username
    fullName
    email
    sourceUrl
    phone
    clientCode
    conversations {
      items {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const createDevice = `mutation CreateDevice($input: CreateDeviceInput!) {
  createDevice(input: $input) {
    id
    username
    deviceToken
    deviceOs
    deviceSnsEndpoint
    active
    createdAt
    updatedAt
  }
}
`;
export const updateDevice = `mutation UpdateDevice($input: UpdateDeviceInput!) {
  updateDevice(input: $input) {
    id
    username
    deviceToken
    deviceOs
    deviceSnsEndpoint
    active
    createdAt
    updatedAt
  }
}
`;
export const deleteDevice = `mutation DeleteDevice($input: DeleteDeviceInput!) {
  deleteDevice(input: $input) {
    id
    username
    deviceToken
    deviceOs
    deviceSnsEndpoint
    active
    createdAt
    updatedAt
  }
}
`;
export const createConversation = `mutation CreateConversation($input: CreateConversationInput!) {
  createConversation(input: $input) {
    id
    user {
      id
      username
      fullName
      email
      sourceUrl
      phone
      clientCode
      conversations {
        nextToken
      }
      createdAt
      updatedAt
    }
    conversationUserId
    members
    sourceUrl
    messages {
      items {
        id
        messageConversationId
        content
        authorId
        createdAt
        updatedAt
      }
      nextToken
    }
    messagesCount
    lastMessage {
      id
      conversation {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      messageConversationId
      content
      authorId
      createdAt
      updatedAt
    }
    conversationLastMessageId
    createdAt
    updatedAt
  }
}
`;
export const createMessage = `mutation CreateMessage($input: CreateMessageInput!) {
  createMessage(input: $input) {
    id
    conversation {
      id
      user {
        id
        username
        fullName
        email
        sourceUrl
        phone
        clientCode
        createdAt
        updatedAt
      }
      conversationUserId
      members
      sourceUrl
      messages {
        nextToken
      }
      messagesCount
      lastMessage {
        id
        messageConversationId
        content
        authorId
        createdAt
        updatedAt
      }
      conversationLastMessageId
      createdAt
      updatedAt
    }
    messageConversationId
    content
    authorId
    createdAt
    updatedAt
  }
}
`;
