// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreateMessage = `subscription OnCreateMessage($messageConversationId: ID!) {
  onCreateMessage(messageConversationId: $messageConversationId) {
    id
    conversation {
      id
      user {
        id
        username
        fullName
        email
        sourceUrl
        phone
        clientCode
        createdAt
        updatedAt
      }
      conversationUserId
      members
      sourceUrl
      messages {
        nextToken
      }
      messagesCount
      lastMessage {
        id
        messageConversationId
        content
        authorId
        createdAt
        updatedAt
      }
      conversationLastMessageId
      createdAt
      updatedAt
    }
    messageConversationId
    content
    authorId
    createdAt
    updatedAt
  }
}
`;
export const onCreateUser = `subscription OnCreateUser {
  onCreateUser {
    id
    username
    fullName
    email
    sourceUrl
    phone
    clientCode
    conversations {
      items {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser {
  onUpdateUser {
    id
    username
    fullName
    email
    sourceUrl
    phone
    clientCode
    conversations {
      items {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const onCreateDevice = `subscription OnCreateDevice {
  onCreateDevice {
    id
    username
    deviceToken
    deviceOs
    deviceSnsEndpoint
    active
    createdAt
    updatedAt
  }
}
`;
export const onUpdateDevice = `subscription OnUpdateDevice {
  onUpdateDevice {
    id
    username
    deviceToken
    deviceOs
    deviceSnsEndpoint
    active
    createdAt
    updatedAt
  }
}
`;
export const onDeleteDevice = `subscription OnDeleteDevice {
  onDeleteDevice {
    id
    username
    deviceToken
    deviceOs
    deviceSnsEndpoint
    active
    createdAt
    updatedAt
  }
}
`;
export const onCreateConversation = `subscription OnCreateConversation {
  onCreateConversation {
    id
    user {
      id
      username
      fullName
      email
      sourceUrl
      phone
      clientCode
      conversations {
        nextToken
      }
      createdAt
      updatedAt
    }
    conversationUserId
    members
    sourceUrl
    messages {
      items {
        id
        messageConversationId
        content
        authorId
        createdAt
        updatedAt
      }
      nextToken
    }
    messagesCount
    lastMessage {
      id
      conversation {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      messageConversationId
      content
      authorId
      createdAt
      updatedAt
    }
    conversationLastMessageId
    createdAt
    updatedAt
  }
}
`;
