// eslint-disable
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    fullName
    email
    sourceUrl
    phone
    clientCode
    conversations {
      items {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      fullName
      email
      sourceUrl
      phone
      clientCode
      conversations {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getDevice = `query GetDevice($id: ID!) {
  getDevice(id: $id) {
    id
    username
    deviceToken
    deviceOs
    deviceSnsEndpoint
    active
    createdAt
    updatedAt
  }
}
`;
export const listDevices = `query ListDevices(
  $filter: ModelDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      deviceToken
      deviceOs
      deviceSnsEndpoint
      active
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getConversation = `query GetConversation($id: ID!) {
  getConversation(id: $id) {
    id
    user {
      id
      username
      fullName
      email
      sourceUrl
      phone
      clientCode
      conversations {
        nextToken
      }
      createdAt
      updatedAt
    }
    conversationUserId
    members
    sourceUrl
    messages {
      items {
        id
        messageConversationId
        content
        authorId
        createdAt
        updatedAt
      }
      nextToken
    }
    messagesCount
    lastMessage {
      id
      conversation {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      messageConversationId
      content
      authorId
      createdAt
      updatedAt
    }
    conversationLastMessageId
    createdAt
    updatedAt
  }
}
`;
export const listConversations = `query ListConversations(
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
) {
  listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user {
        id
        username
        fullName
        email
        sourceUrl
        phone
        clientCode
        createdAt
        updatedAt
      }
      conversationUserId
      members
      sourceUrl
      messages {
        nextToken
      }
      messagesCount
      lastMessage {
        id
        messageConversationId
        content
        authorId
        createdAt
        updatedAt
      }
      conversationLastMessageId
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getMessage = `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    conversation {
      id
      user {
        id
        username
        fullName
        email
        sourceUrl
        phone
        clientCode
        createdAt
        updatedAt
      }
      conversationUserId
      members
      sourceUrl
      messages {
        nextToken
      }
      messagesCount
      lastMessage {
        id
        messageConversationId
        content
        authorId
        createdAt
        updatedAt
      }
      conversationLastMessageId
      createdAt
      updatedAt
    }
    messageConversationId
    content
    authorId
    createdAt
    updatedAt
  }
}
`;
export const listMessages = `query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conversation {
        id
        conversationUserId
        members
        sourceUrl
        messagesCount
        conversationLastMessageId
        createdAt
        updatedAt
      }
      messageConversationId
      content
      authorId
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
