//import './css/style.css'
//import './css/kaydoh-chat.css'

// Needed for Hot Module Replacement
if (typeof (module.hot) !== 'undefined') {
    module.hot.accept() // eslint-disable-line no-undef
}

import * as KChat from "./js/kaydoh-chat"

const chatConfig = new KChat.ChatConfig();
chatConfig.welcomeMessage = "Hi there 👋, to quickly reach out to me, click on one of the buttons below.";
chatConfig.emailSolicitation = "Quickly just in case we get disconnected, what's your email and I'll follow up ASAP.";
chatConfig.availableOnline = true;
if (window.kaydohChatConfig) {
    chatConfig.landingPageUrl = window.kaydohChatConfig.landingPageUrl;
    chatConfig.landingPageCode = window.kaydohChatConfig.landingPageCode;
}
if (chatConfig.landingPageUrl == null) {
    chatConfig.landingPageUrl = window.location.href;
}
console.log(chatConfig.landingPageUrl);

let url = window.kaydohChatConfig ? window.kaydohChatConfig.url : window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
let clientId = window.kaydohChatConfig ? window.kaydohChatConfig.clientId : window.location.search.substring(1).split("&").filter(value => value.indexOf("clientId=") === 0)[0].replace("clientId=", "");
window.kaydohChat = new KChat.KaydohChat(url, clientId, chatConfig, document.body);
console.log("done?")